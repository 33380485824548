import React from "react";

function IconReportSettings({ height = "16", width = "16", color = "black" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path d="M7.33333 4.66732H8.66667V11.334H7.33333V4.66732ZM10 7.33398H11.3333V11.334H10V7.33398ZM4.66667 8.66732H6V11.334H4.66667V8.66732ZM10 2.66732H3.33333V13.334H12.6667V5.33398H10V2.66732ZM2 1.99518C2 1.63002 2.29833 1.33398 2.66567 1.33398H10.6667L13.9998 4.66732L14 13.9957C14 14.3666 13.7034 14.6673 13.3377 14.6673H2.66227C2.29651 14.6673 2 14.3638 2 14.0061V1.99518Z" fill={color} />
    </svg>
  );
}

export default IconReportSettings;

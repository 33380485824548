import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";
import { settings } from "../../../Constants/MetaDetails";

const ReportSettingsComponent = lazy(() => import("../../Components/ReportSettingsComponent"));

function ReportSettingsContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.reportSettings}
      meta={settings?.reportSettings?.subtitle}
      withLayout={false}
    >
      <ReportSettingsComponent />
    </PageContainer>
  );
}

export default ReportSettingsContainer;

import { node, string } from "prop-types";
import React from "react";
import styles from "./form-card.module.scss";

export default function FormCard({
  title,
  description,
  className,
  contentClassName,
  children,
  rightContent,
}) {
  return (
    <div className={`center-block ${styles.card} w-100 ${className || ""}`}>
      <div className={styles.cardHeader}>
        <div className={styles.left}>
          <h6 className={styles.title}>{title}</h6>
          <p className={`caption ${styles.caption}`}>{description}</p>
        </div>
        {rightContent ? <div className={styles.right}>{rightContent}</div> : null}
      </div>
      <div className={`${styles.cardContent} ${contentClassName || ""}`}>{children}</div>
    </div>
  );
}

FormCard.propTypes = {
  title: string.isRequired,
  description: string,
  className: string,
  contentClassName: string,
  children: node,
  rightContent: node,
};

FormCard.defaultProps = {
  description: "",
  className: "",
  contentClassName: "",
  children: null,
  rightContent: null,
};

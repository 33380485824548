import React, { useEffect, useRef } from "react";
import drawImage from "./Service/drawImage";

function CanvasImageComponent(props) {
  const canvasRef = useRef(null);
  const polygons = [
    {
      coordinates: props?.coordinates,
    },
  ];

  useEffect(() => {
    drawImage(props?.imageSrc, polygons, canvasRef, props?.obscureData);
  }, [props?.imageSrc, props?.obscureData]);

  return (
    <canvas
      onClick={props?.onClick}
      ref={canvasRef}
      style={{ width: "100%", height: "100%" }}
    />
  );
}

export default CanvasImageComponent;

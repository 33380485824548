import { ONBOARD_WO_CAM_DEVICE } from "../../Constants";
import { getFormattedRegionsOptions } from "../../Services/addDeviceForm";
import fetchCameraOfflineAlertEmailList from "../../Services/getCameraOfflineAlertEmailList";
import { showAlert } from "../../Services/showAlert";
import { isSourceHLS } from "../../Services/validation";
import { store } from "../../Store";
import {
  fetchCameraDetails,
  getOnPremiseDevices,
  getOpenEyeDevices,
  woConnectRequests,
} from "../Requests";
import {
  ADD_DEVICE_STEPS,
  CAMERA_TYPES,
  DEVICE_TYPES,
  DEVICE_TYPES_SUB_STEP,
  getValue,
  smartStreamerBitRate,
  smartStreamerDetectionMode,
  smartStreamerFramerate,
  smartStreamerOutPutQuality,
  smartStreamerResolution,
} from "../State";
import {
  addDeviceDetails,
  clearAddNewDeviceFormData,
  disableFields,
  handleRedirectOnSave,
  handleRefresh,
  setAddNewDeviceFormStep,
  setAddedDeviceData,
  setCameraName,
  setConnectedCameras,
  setDeviceOfflineAlertEmailList,
  setEditType,
  setEditingMode,
  setIsEditOtherDetails,
  setIsGlobalSubmit,
  setLocationAndRegion,
  setManufacturerName,
  setNewDeviceType,
  setPreConnectedCameras,
  setSelectedFieldValue,
  setSubSteps,
  setWoConnectDetails,
  setWoConnectVerifyDetails,
  setupWoConnect,
  submitNewDeviceForm,
  updateFormOptions,
  updateOtherDetails,
} from "../State/newDeviceAction";
import { getRegions } from "../State/newDeviceReducer";

export async function verifyWoConnectSetupUseCase(connectDeviceCode) {
  const dispatch = (action) => store.dispatch(action);
  try {
    // const storeState = store.getState();
    // const deviceId = getAddedDeviceId(storeState);

    dispatch(setWoConnectVerifyDetails(true));

    const response = await woConnectRequests.verify(connectDeviceCode);
    if (!response?.data?.connectedToDevice) {
      showAlert("Device verification failed", "error");
    } else {
      showAlert("Device verified successfully");
    }

    dispatch(setWoConnectVerifyDetails(false, response.data));
  } catch (ex) {
    dispatch(setWoConnectVerifyDetails(false, null, ex));
    showAlert("Device verification failed", "error");
  }
}

export function handleAddNewDevice({ currentPath, history }) {
  const dispatch = (action) => store.dispatch(action);
  dispatch(clearAddNewDeviceFormData());
  dispatch(handleRedirectOnSave(currentPath));
  if (history) {
    history.push(ONBOARD_WO_CAM_DEVICE);
  }
}
export async function handleAddNewCameraOnSelectedLocation({
  currentPath,
  history,
  cityId,
  locationId,
}) {
  const storeState = store.getState();
  const dispatch = (action) => store.dispatch(action);
  await dispatch(clearAddNewDeviceFormData(store.getState()));
  const regions = getRegions(storeState);
  const helpers = {
    dispatch,
    getState: store.getState,
  };
  await setLocationAndRegion({
    city: cityId,
    location: locationId,
    regions,
    ...helpers,
  });
  await dispatch(disableFields({ fieldName: "location", fieldValue: true }));
  await dispatch(disableFields({ fieldName: "region", fieldValue: true }));
  await dispatch(handleRedirectOnSave(currentPath));
  history.push(ONBOARD_WO_CAM_DEVICE);
}

export async function getOpenEyeDeviceOptionsUseCase() {
  try {
    const storeState = store.getState();
    const { openEyeDeviceOptions } = storeState.newDevice.options || {};

    if (openEyeDeviceOptions?.length) {
      return;
    }

    const devices = await getOpenEyeDevices();
    const options = (devices?.data || []).map((device) => ({
      label: device.name,
      value: device.id,
      details: device,
      added: device.added,
      isDisabled: device.added,
    }));

    const { enabledOptions, disabledOptions } = options.reduce(
      (acc, option) => {
        if (option.isDisabled) {
          acc.disabledOptions.push(option);
        } else {
          acc.enabledOptions.push(option);
        }
        return acc;
      },
      { enabledOptions: [], disabledOptions: [] }
    );

    const sortedOptions = [...enabledOptions, ...disabledOptions];

    store.dispatch(updateFormOptions({ openEyeDeviceOptions: sortedOptions }));
    store.dispatch(handleRefresh(false));
  } catch (ex) {
    // handled error
  }
}
export async function getOnPremiseDeviceOptionsUseCase({ locationId, alert = false }) {
  try {
    if (!locationId) {
      return;
    }

    const devices = await getOnPremiseDevices({ locationId });
    const onlineData = [];
    const offlineData = [];
    const notEligibleData = [];
    devices.data.connectedDevices.forEach((el) => {
      if (el.deviceStatus === "Online") {
        if (el.deviceUserType !== "DesktopApp") {
          onlineData.push({
            label: el.deviceDisplayName || el.systemInfo?.deviceName || el.deviceId,
            value: el.deviceId,
            isDisabled: el.deviceUserType === "DesktopApp",
          });
        } else {
          notEligibleData.push({
            label: `${
              el.deviceDisplayName || el.systemInfo?.deviceName || el.deviceId
            } (Not Eligible)`,
            value: el.deviceId,
            isDisabled: el.deviceUserType === "DesktopApp",
          });
        }
      } else {
        offlineData.push({
          label: `${el.deviceDisplayName || el.systemInfo?.deviceName || el.deviceId} (Offline)`,
          value: el.deviceId,
          isDisabled: true,
        });
      }
    });
    store.dispatch(
      updateFormOptions({
        onPremiseDeviceOptions: [...onlineData, ...notEligibleData, ...offlineData],
      })
    );
    if (alert) {
      showAlert("Device options fetched successfully");
    }
    store.dispatch(handleRefresh(false));
  } catch (ex) {
    // handled error
  }
}

export async function fetchWoConnectDetailsUseCase() {
  try {
    const {
      newDevice: {
        deviceType,
        technicalDetails: { location, openEyeDevice },
      },
    } = store.getState();
    const locationId = getValue(location);

    store.dispatch(setWoConnectDetails(true));
    const isStandalone = deviceType === DEVICE_TYPES.STAND_ALONE_CAMERA;
    const response = await woConnectRequests.fetch({
      locationId,
      isStandalone,
    });
    let connectedDeviceData = [];
    if (isStandalone) {
      connectedDeviceData = response?.data ? [response?.data] : [];
    } else {
      connectedDeviceData = (response?.data || []).filter(
        (el) => el?.dvr === openEyeDevice?.details?.name
      );
    }

    store.dispatch(setWoConnectDetails(false, connectedDeviceData));
  } catch (ex) {
    store.dispatch(setWoConnectDetails(false, {}, ex));
  }
}

export async function fetchCameraDetailsUseCase() {
  try {
    const { newDevice: newDeviceData } = store.getState();

    store.dispatch(setConnectedCameras(true));
    if (!newDeviceData.technicalDetails.openEyeDevice?.value) return;
    const openEyeDeviceId = newDeviceData.technicalDetails.openEyeDevice?.value;
    if (!openEyeDeviceId) return;
    const { data } = await fetchCameraDetails({ openEyeDeviceId });

    store.dispatch(setConnectedCameras(false, data));
  } catch (ex) {
    store.dispatch(setConnectedCameras(false, null, ex));
  }
}
export async function fetchCameraOfflineEmailListUseCase() {
  try {
    const {
      newDevice: {
        technicalDetails: { location },
      },
    } = store.getState();
    const locationId = getValue(location);

    const { data } = await fetchCameraOfflineAlertEmailList({ locationId });

    store.dispatch(setDeviceOfflineAlertEmailList(data));
  } catch (ex) {
    store.dispatch(setDeviceOfflineAlertEmailList([]));
  }
}
const mapOption = (optionList, value, key = "value") =>
  optionList.filter((option) => option[key] === value?.toString());
export async function handleUpdateDevice({
  deviceType,
  currentPath,
  deviceDetails,
  editOtherDetails,
  setupWoConnect: isEditWoConnectEditMode,
  editType,
}) {
  // =====================
  // Clear previous state and then set new data to redirect on edit page
  // =====================
  handleAddNewDevice({ currentPath });
  // =====================

  const storeState = store.getState();
  const dispatch = (action) => store.dispatch(action);
  const { newDevice: newDeviceData } = storeState;
  const { openEyeDeviceOptions } = newDeviceData?.options || {};
  const manufacturerOptions = storeState?.userData?.manifest?.manufacturer;
  const regions = getRegions(storeState);
  const location = {
    city: deviceDetails?._city,
    location: deviceDetails?._location,
  };
  // Helper object with the current dispatch and getState
  const helpers = {
    dispatch,
    getState: store.getState,
  };
  dispatch(setEditType(editType));
  dispatch(setEditingMode(true));
  dispatch(addDeviceDetails(deviceDetails));

  // const manufacturerList = [deviceDetails?.manufacturerName, ...manufacturerOptions];
  dispatch(
    updateFormOptions({
      regionsOptions: getFormattedRegionsOptions(regions),
      // manufacturerOptions: manufacturerList,
    })
  );

  if (editOtherDetails) {
    dispatch(setIsEditOtherDetails(true));
    dispatch(setAddedDeviceData({ dvr: deviceDetails }));
  }
  const setupWoConnectEdit = () => {
    if (isEditWoConnectEditMode) {
      dispatch(
        updateOtherDetails({
          fieldName: "runAITaskOnOE",
          fieldValue: true,
        })
      );
      dispatch(setupWoConnect(true));
    }
  };
  const setOtherDetails = () => {
    dispatch(
      updateOtherDetails({
        fieldName: "miniOfflineDuration",
        fieldValue: deviceDetails?.cameraOfflineAlertDuration || "",
      })
    );
    dispatch(
      updateOtherDetails({
        fieldName: "subscribeUsers",
        fieldValue: deviceDetails?.emailIds?.map((email) => ({
          label: email,
          value: email,
          isSelected: true,
        })),
      }) || []
    );

    if (deviceType === DEVICE_TYPES.OPEN_EYE_CLOUD_CAMERA || editType === "wmsCamera") {
      if (deviceDetails?.connectedToDeviceId && deviceDetails?.smartStreamer) {
        const {
          backgroundRedaction,
          smartStreamer: {
            mode,
            enabled,
            resolution: { width, framerate, quality, bitrate } = {},
          } = {},
        } = deviceDetails || {};
        dispatch(
          updateOtherDetails({
            fieldName: "smartStreamerEnabled",
            fieldValue: enabled,
          })
        );
        dispatch(
          updateOtherDetails({
            fieldName: "smartStreamer",
            fieldValue: {
              detectionMode: mapOption(smartStreamerDetectionMode, mode),
              resolution: mapOption(smartStreamerResolution, width, "width"),
              framerate: mapOption(smartStreamerFramerate, framerate),
              quality: mapOption(smartStreamerOutPutQuality, quality),
              bitrate: mapOption(smartStreamerBitRate, bitrate),
              backgroundRedaction,
            },
          })
        );
      }
    }
    if (deviceType === DEVICE_TYPES.OPEN_EYE_CLOUD_CAMERA) {
      dispatch(
        updateOtherDetails({
          fieldName: "deviceMiniOfflineDuration",
          fieldValue:
            deviceDetails?.deviceOfflineAlertDuration < 5
              ? 5
              : deviceDetails?.deviceOfflineAlertDuration || "",
        })
      );
      dispatch(
        updateOtherDetails({
          fieldName: "deviceSubscribeUsers",
          fieldValue:
            deviceDetails?.deviceOfflineEmailIds?.map((user) => ({
              label: user.email,
              value: user.email,
              isSelected: true,
            })) || [],
        })
      );
    }
  };
  const getOpenEyeDeviceDetails = () => ({
    label: deviceDetails?.dvr,
    value: deviceDetails?.openeyeDeviceId,
    details: {
      id: deviceDetails?.openeyeDeviceId,
      name: deviceDetails?.dvr,
      added: true,
    },
  });

  switch (deviceType) {
    case DEVICE_TYPES.OPEN_EYE_CLOUD_CAMERA:
      // Edit openEye nvr
      dispatch(setNewDeviceType(DEVICE_TYPES.OPEN_EYE_CLOUD_CAMERA));
      setLocationAndRegion({
        ...location,
        regions,
        ...helpers,
      });
      setSelectedFieldValue({
        key: "openEyeDevice",
        value: getOpenEyeDeviceDetails(),
        ...helpers,
      });
      // await getOpenEyeDeviceOptionsUseCase();
      dispatch(
        updateFormOptions({
          openEyeDeviceOptions: [getOpenEyeDeviceDetails(), ...openEyeDeviceOptions],
        })
      );
      setOtherDetails();
      // setupWoConnectEdit();
      dispatch(setSubSteps(DEVICE_TYPES_SUB_STEP.ADD_CAMERAS));
      break;

    case DEVICE_TYPES.CLOUD_CAMERA:
      // Edit camera
      dispatch(setNewDeviceType(DEVICE_TYPES.CLOUD_CAMERA));
      setLocationAndRegion({
        ...location,
        regions,
        ...helpers,
      });
      setCameraName({ name: deviceDetails?.camera, ...helpers });
      setManufacturerName({
        manufacturer: deviceDetails?.manufacturer,
        ...helpers,
      });
      dispatch(
        updateFormOptions({
          manufacturerOptions: [
            { label: deviceDetails?.manufacturer, value: deviceDetails?.manufacturer },
            ...(manufacturerOptions?.map((m) => ({
              label: m,
              value: m,
            })) || []),
          ],
        })
      );
      setSelectedFieldValue({
        key: "isProxyStream",
        value: deviceDetails?.isProxyStream ? deviceDetails?.isProxyStream : false,
        ...helpers,
      });
      setSelectedFieldValue({
        key: "cameraType",
        value: isSourceHLS(deviceDetails?.sourceType) ? CAMERA_TYPES.HLS : CAMERA_TYPES.RTSP,
        ...helpers,
      });
      setOtherDetails();
      break;

    case DEVICE_TYPES.CLOUD_NVR:
      // Edit cloud nvr
      dispatch(setNewDeviceType(DEVICE_TYPES.CLOUD_NVR));
      setLocationAndRegion({
        ...location,
        regions,
        ...helpers,
      });
      setCameraName({ name: deviceDetails?.dvr, ...helpers });
      setManufacturerName({
        manufacturer: deviceDetails?.manufacturer,
        ...helpers,
      });
      dispatch(
        updateFormOptions({
          manufacturerOptions: [
            // { label: deviceDetails?.manufacturer, value: deviceDetails?.manufacturer },
            ...(manufacturerOptions?.map((m) => ({
              label: m,
              value: m,
            })) || []),
          ],
        })
      );
      setSelectedFieldValue({
        key: "ipAddress",
        value: deviceDetails?.host,
        ...helpers,
      });
      setSelectedFieldValue({
        key: "port",
        value: deviceDetails?.port,
        ...helpers,
      });
      setSelectedFieldValue({
        key: "nvrChannelId",
        value: {
          label: String(deviceDetails?.channels),
          value: String(deviceDetails?.channels),
        },

        ...helpers,
      });
      setSelectedFieldValue({
        key: "nvrUsername",
        value: deviceDetails?.username,
        ...helpers,
      });
      dispatch(setPreConnectedCameras(deviceDetails?.connectedCamera));
      setOtherDetails();
      break;

    default:
      break;
  }

  dispatch(submitNewDeviceForm(true));
}
export async function handleUpdateDeviceOtherDetails({
  deviceType,
  currentPath,
  deviceDetails,
  setupWoConnect: isSetWocconect,
  editType,
}) {
  const dispatch = (action) => store.dispatch(action);

  try {
    await handleUpdateDevice({
      deviceType,
      currentPath,
      deviceDetails,
      editOtherDetails: true,
      setupWoConnect: isSetWocconect,
      editType,
    });
    dispatch(setAddNewDeviceFormStep(ADD_DEVICE_STEPS.OTHER_DETAILS));
    dispatch(setIsGlobalSubmit(true));
  } catch (error) {
    showAlert("Something went wrong");
  }
}
